import { TextField, Alert, IconButton, InputAdornment } from "@mui/material";
import { useLocation } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { useState, useEffect } from "react";
import { LeftSectionHero } from "../components";
import { PasswordResetValidation } from "../validation/PasswordResetValidation";
import { useFormik } from "formik";

const api = new APICoreLiteSignup();
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const VerifyUserNew = ({ mode }) => {
  let query = useQuery();
  let token = query.get("token");
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({
    message: "",
    show: false,
    severity: "",
  });

  const [showPassword, toggleShowPassword] = useState(false);
  const [showPasswordConfirm, toggleShowPasswordConfirm] = useState(false);

  const handleSignup = async (values) => {
    setLoading(true);
    toggleShowPasswordConfirm(false);
    toggleShowPassword(false);

    await api
      .postCompleteUser({
        username: email,
        password: values.password,
        token: token,
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          formik.resetForm();
          setAlert({
            message:
              mode === "signup"
                ? "Account created - You may now login!"
                : "Password Reset",
            show: true,
            severity: "success",
          });
          setEmail("");
        } else {
          setAlert({
            message:
              mode === "signup"
                ? "Error creating account"
                : "Error resetting password",
            show: true,
            severity: "error",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          message:
            mode === "signup"
              ? "There was a problem creating your account. Please try again later."
              : "There was a problem resetting the password for your account. Please try again later.",
          show: true,
          severity: "error",
        });
      });
  };
  useEffect(() => {
    api.getUserUsingToken({ token }).then((res) => {
      setLoading(false);
      res.data
        ? setEmail(res.data)
        : setAlert({
            message: "Invalid Token",
            show: true,
            severity: "error",
          });
    });
  }, [token]);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: PasswordResetValidation,
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  return (
    <div className="h-full w-full sm:px-0">
      <div className="flex flex-col h-full sm:flex-row">
        {/* Left content */}
        <LeftSectionHero title="SolutionView" subtitle="Account Setup" />
        {/* Right content */}
        <div className="flex flex-col flex-grow min-h-0 p-3 sm:w-2/3">
          <div className="flex flex-col flex-grow h-full items-center overflow-auto w-full sm:py-12">
            <div className="m-auto-0 w-full sm:max-w-lg">
              {email && (
                <div className="mb-2">
                  {mode === "signup" ? (
                    <>
                      <h1 className="mb-2 text-xl">Welcome to SolutionView!</h1>

                      <p className="text-gray-600">
                        Your account has been created under the follwing email:
                      </p>

                      <p className="text-gray-900">{email}</p>
                    </>
                  ) : (
                    <>
                      <h1 className="mb-2 text-xl">Reset your password here</h1>
                      <p>
                        You are resetting your password for the following email:
                      </p>
                      <p>{email}</p>
                    </>
                  )}

                  <p className="pt-4 text-gray-600">
                    Please set a password below to access your account.
                  </p>

                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <TextField
                      autoComplete="new-password"
                      name="password"
                      placeholder="New Password"
                      aria-label="Password"
                      label="Password"
                      margin="normal"
                      fullWidth
                      inputProps={{ "aria-label": "Password" }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      type={showPassword ? "text" : "password"}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      onBlur={formik.handleBlur}
                      disabled={isLoading}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              disabled={isLoading}
                              onClick={(e) => {
                                e.preventDefault();
                                toggleShowPassword(!showPassword);
                                toggleShowPasswordConfirm(false);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      autoComplete="new-password"
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="New Password"
                      aria-label="confirm password"
                      margin="normal"
                      fullWidth
                      inputProps={{ "aria-label": "Confirm Password" }}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      type={showPasswordConfirm ? "text" : "password"}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                      onBlur={formik.handleBlur}
                      disabled={isLoading}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              disabled={isLoading}
                              onClick={(e) => {
                                e.preventDefault();
                                toggleShowPasswordConfirm(!showPasswordConfirm);
                                toggleShowPassword(false);
                              }}
                            >
                              {showPasswordConfirm ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <button
                      type="submit"
                      className="active bg-primary-500 hover:bg-primary-500-dark px-4 py-2 rounded text-base text-white"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {formik.isSubmitting ? "Setting.." : "Set Password"}
                    </button>
                  </form>
                </div>
              )}
              {alert.show && (
                <Alert severity={alert.severity}>{alert.message}</Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUserNew;
