import React from "react";
import { ForgotPassword, VerifyUserNew, NotFound } from "pages";

import { FormRouter } from "components/";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import "styles/app-styles.scss";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/" component={FormRouter} />
          <Route path="/register" component={FormRouter} />
          <Route path="/workspace/:workspace" component={FormRouter} />
          <Route path="/redirect" component={FormRouter} />
          <Route path="/signup">
            <VerifyUserNew mode={"signup"} />
          </Route>
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/reset">
            <VerifyUserNew mode={"reset"} />
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
