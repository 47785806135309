import { MobileHeader, LeftSectionHero } from ".";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
const SmoothLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`& .${linearProgressClasses.bar}`]: {
    transition: "ease-out",
    transitionDuration: "0.75s",
  },
}));
const FormpageWrapper = ({ screen, form, progress, headerContent }) => {
  if (!form) {
    return screen;
  } else {
    return (
      <div className="h-full w-full sm:px-0">
        <div className="flex flex-col h-full sm:flex-row">
          {/* Left content */}
          <LeftSectionHero title={headerContent.title} subtitle={headerContent.subtitle} />
          {/* Right content */}

          <div className="flex flex-col flex-grow min-h-0">
            <MobileHeader />
            {progress > 0 ? <SmoothLinearProgress variant="determinate" color="secondary" value={progress} /> : null}

            {form}
          </div>
        </div>
      </div>
    );
  }
};
export default FormpageWrapper;
