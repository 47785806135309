import { Alert } from "@mui/material";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { useState, useEffect } from "react";
import { FooterNav } from "components";
import LoadingPleaseWait from "@supportworks/react-components/dist/LoadingPleaseWait";
const api = new APICoreLiteSignup();

export const ChoosePath = ({ prevStep, values, nextStep }) => {
  const [error, setError] = useState("");
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  useEffect(() => {
    api
      .getBranches()
      .then((result) => {
        if (result.status === 200) {
          let data = result.data;
          setBranches(data);
        } else {
          setError("There was an error loading the industry-secific demos - Please try again later.");
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        setError("There was an error loading the industry-secific demos - Please try again later.");
      });
  }, []);

  const handleTestDriveSubmit = async (values) => {
    let json = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      company: values.company,
      branch: selectedBranch,
    };
    console.log(json);
    await api
      .postSignup(json)
      .then((result) => {
        if (result.status === 200) {
          nextStep();
        } else {
          setError("There was an error submitting the application. — Please try again later.");
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        throw new Error(err);
      });
  };

  return (
    <>
      <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 py-4 w-full sm:py-12">
        <div className="m-auto-0 w-full sm:max-w-lg">
          <div className="mb-2">
            <h1 className="font-bold mb-2 text-4xl">What is your industry?</h1>
            <p className="mb-10 text-gray-600">Let us know, and we'll provide you with an industry-specific SolutionView demo.</p>

            {/* Checkboxes pick path */}
            <div className="mt-12 space-y-4">
              <h2 className="font-bold mb-2">Select your industry</h2>
              {branches ? (
                <>
                  {branches.map((branch) => (
                    <div key={branch.id} id={branch.id} className={`border-solid rounded-lg p-2 cursor-pointer ${selectedBranch === branch.id ? "bg-blue-100 border-blue-600 border-2" : "border-gray-300 hover:border-blue-500 hover:bg-blue-50 border"} `} onClick={(e) => setSelectedBranch(branch.id)}>
                      <h3 className="text-base">{branch.name}</h3>
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  <LoadingPleaseWait />
                </div>
              )}
            </div>
          </div>

          {error && <Alert severity="error">{error}</Alert>}
        </div>
      </div>

      <FooterNav nextCallback={(e) => handleTestDriveSubmit(values)} nextDisabled={!selectedBranch} backCallback={prevStep} nextText="Submit" />
    </>
  );
};

export default ChoosePath;
