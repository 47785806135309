import React, { Component } from "react";
import { TextField, FormLabel, MenuItem } from "@mui/material";
import { LoadingPleaseWait } from "@supportworks/react-components";
import cookies from "lib/cookies";
import _ from "lodash";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { FooterNav } from "components";
import { withRouter } from "react-router-dom";
const api = new APICoreLiteSignup();

/*
 * READ THIS FIRST.  Lots of stuff going on here so I've tried to summarize in English.
 *
 * Authorization is a single step, but it has multiple parts that fire in a smoke and mirrors fashion.
 *
 * 1. Email & Workspace validation to get this thing called auth "context" to figure out the auth step.
 *
 * A single context contains rules for a SINGLE CRM that explain how the user should authorize.
 * The two context types are for redirect authorization (GPS) and username/password authorization (Vonigo).
 *
 * - Enter Email & Workspace to get context
 * - continue() function is called and redirects if the context is for redirect
 *
 * 2. Username and Password validation
 *
 * Enter Username, Password, press the Next button
 * continue() function is called and the username/password payload method is executed, results are verified.
 *
 */

export class LinkSolutionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      workspaces: [],
      showUsernamePasswordComponent: false,
      disableNextButton: false,
      context: "",
      errorMessage: "",
      errorMessages: {},
      prefilledWorkspace: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.workspaceInput = React.createRef();
  }

  componentDidMount() {
    const paramsWorkspace = this.props.match.params.workspace;
    // if paramsWorkspace is set, then we can assign it to the workspace field
    if (paramsWorkspace) {
      this.setState({ prefilledWorkspace: true });
      this.props.setCookie({ workspace: paramsWorkspace });
      this.props.setStateParent("workspace", paramsWorkspace);
    }
    this.setState({ isLoading: false });
  }

  continue = (path) => async (e) => {
    e.preventDefault();
    const { values } = this.props;

    // Error Checking
    let errorMessages = {};
    if (!values.email && !this.state.prefilledWorkspace) {
      errorMessages["email"] = "Missing email.";
    }
    if (!values.workspace) {
      errorMessages["workspace"] = "Missing workspace.";
    }
    if (!_.isEmpty(errorMessages)) {
      this.setState({ errorMessages: errorMessages });
      return;
    }

    // Get my context
    let result = await api.getContext(values.workspace);
    let context;
    if (result.status === 200) {
      context = result.data;
      await this.props.setStateParent({ context: context });
      if (context.collectUsername === true) {
        if (this.state.showUsernamePasswordComponent === true) {
          if (!values.username) {
            errorMessages["username"] = "Missing username.";
          }
          if (!values.password) {
            errorMessages["password"] = "Missing password.";
          }
          if (!_.isEmpty(errorMessages)) {
            this.setState({ errorMessages: errorMessages });
            return;
          }
        } else {
          this.setState({ showUsernamePasswordComponent: true });
          return;
        }
      }
    } else {
      // could not find context.
      this.setState({ errorMessage: "Invalid workspace." });
      return;
    }

    // Authorization
    let payload;
    let c = cookies.get();
    c = {
      ...c,
      workspace: values.workspace,
      email: values.email,
      username: values.username,
      context: context,
    };

    if (context.collectUsername === false) {
      //sadly we have to do this because the way cookies work they are based on path so /redirect is not the same as /workspace..
      let redirectUrl = "http://" + window.location.host + "/workspace/" + values.workspace;
      payload = {
        context: values.workspace,
        redirectUrl: redirectUrl,
      };
      c.path = path;
      c.step = values.step + 1;
    } else {
      // CONTEXT for username & password auth
      payload = {
        context: values.workspace,
        username: values.username,
        password: values.password,
      };
    }
    cookies.set(c);
    try {
      console.log("payload", payload);
      let result = await api.authenticate(payload);
      console.log("result", result);
      if (result.status === 200) {
        this.props.nextStep(path);
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "We apologize, there was an error.",
        });
      }
    } catch (err) {
      if (context.collectUsername === true) {
        this.setState({
          isLoading: false,
          errorMessage: "We apologize, there was an error.",
        });
      }
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  async handleEmailChange(e) {
    await api
      .getWorkspacesUsingEmail(e.target.value)
      .then((result) => {
        if (result.status === 200 && result.data.length) {
          this.setState({ workspaces: result.data });
        } else {
          //this.setState({ infoMessage: "We did not find any existing workspaces for that email. Please continue."});
        }
        this.workspaceInput.current.focus();
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  }

  render() {
    const { values, handleChange } = this.props;

    if (this.state.isLoading) {
      return <LoadingPleaseWait />;
    }

    let Workspace;
    if (this.state.workspaces.length === 0) {
      Workspace = (
        <TextField
          disabled={!values.email}
          name="workspace"
          label="Workspace"
          placeholder="Enter Your Workspace"
          margin="normal"
          fullWidth
          onChange={handleChange("workspace")}
          defaultValue={values.workspace}
          inputRef={this.workspaceInput}
          error={this.state.errorMessages.workspace}
          helperText={this.state.errorMessages.workspace}
          value={values.workspace}
        />
      );
    } else {
      let cRef = this;
      Workspace = (
        <>
          <TextField select name="workspace" label="Select Your Workspace" value={values.workspace} margin="normal" fullWidth onChange={handleChange("workspace")} inputRef={this.workspaceInput} error={this.state.errorMessages.workspace} helperText={this.state.errorMessages.workspace}>
            {cRef.state.workspaces.map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full md:py-12">
          <div className="m-auto-0 w-full md:max-w-lg">
            <form>
              <h1 className="font-semibold py-2 text-4xl">Let's streamline the setup process!</h1>

              <p className="pb-2 text-gray-600">If your company is using SolutionView, you should already have a Workspace name. </p>
              {!this.state.prefilledWorkspace ? (
                <>
                  <p className="text-gray-600">Please enter your Workspace and email.</p>
                  <TextField name="email" placeholder="Enter Email" label="Email" onChange={handleChange("email")} onBlur={this.handleEmailChange} defaultValue={values.email} margin="normal" fullWidth error={this.state.errorMessages.email} helperText={this.state.errorMessages.email} />
                </>
              ) : (
                <>
                  <p className="text-gray-600">Confirm the workspace below is correct, then press continue.</p>
                </>
              )}

              {Workspace}
              {this.state.showUsernamePasswordComponent ? <UsernamePassword username={this.state.username} password={this.state.password} errorMessages={this.state.errorMessages} handleChange={this.props.handleChange} /> : null}
              {this.state.showUsernamePasswordComponent ? <p className="text-gray-600">If you don't know your credentials, please contact an organization administrator.</p> : <p className="text-gray-600">If you don't know your Workspace name, please contact an organization administrator.</p>}
            </form>
          </div>
        </div>
        <FooterNav nextCallback={this.continue(values.path)} nextDisabled={this.state.disableNextButton} errorMessage={this.state.errorMessage} />
      </>
    );
  }
}

class UsernamePassword extends React.Component {
  render() {
    let props = this.props;
    return (
      <>
        <br />
        <br />
        <FormLabel component="legend">Please enter your username and password:</FormLabel>
        <TextField name="username" placeholder="Enter username" label="Username" onChange={props.handleChange("username")} defaultValue={props.username} margin="normal" fullWidth error={props.errorMessages.username} helperText={props.errorMessages.username} />
        <TextField name="password" type="password" placeholder="Enter password" label="Password" autoComplete="off" onChange={props.handleChange("password")} defaultValue={props.password} margin="normal" fullWidth error={props.errorMessages.password} helperText={props.errorMessages.password} />
      </>
    );
  }
}

export default withRouter(LinkSolutionView);
