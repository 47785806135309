import { Dialog, DialogContentText, DialogContent } from "@mui/material";
import DialogTitle from "./DialogTitle";

// A helper component to present a MUI dialog on click based on content passed in via. props..
const HelpBubble = ({ title, content, open, setOpen }) => {
  return (
    <div>
      {/* BUTTON TO OPEN DIALOG */}
      <div onClick={() => setOpen(true)} className="cursor-pointer flex group-hover:text-blue-400 items-start justify-center space-x-2">
        <div className="bg-blue-500 flex h-6 hover:bg-blue-400 items-center justify-center rounded-full text-white w-6">
          <p className="text-white">?</p>
        </div>
        <div>
          <h3 className="hover:text-blue-400 text-blue-500">{title}</h3>
        </div>
      </div>
      {/* Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="what-is-a-workspace-dialog-title" aria-describedby="what-is-a-workspace-dialog" fullScreen={false}>
        <DialogTitle onClose={() => setOpen(false)}>
          <h1 className="font-bold text-xl">{title}</h1>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="space-y-4" tabIndex={-1}>
            {/* render content array in paragraph form */}
            {content ? content.map((item, index) => <p key={index}>{item}</p>) : ""}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HelpBubble;
