import React from "react";
import { withStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

function DialogTitle(props) {
  const { children, classes, onClose, TypographyProps } = props;

  return (
    <div className={classes.root}>
      <Typography {...TypographyProps}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
}

DialogTitle.displayName = "DialogTitle";

export default withStyles(styles)(DialogTitle);
