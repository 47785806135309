import React, { Component } from "react";
import { FooterNav } from "../../..";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import dateFormat from "dateformat";

const api = new APICoreLiteSignup();

export class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      billingItems: [],
      billingDate: "",
      billingTotal: "",
    };
    this.handleModifyUsers = this.handleModifyUsers.bind(this);
    this.handleModifyBilling = this.handleModifyBilling.bind(this);
  }

  continue = (path) => (e) => {
    e.preventDefault();
    this.props.nextStep(path);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  async handleModifyBilling() {
    // reset billing token
    this.props.setStateParent("billing_token", "");
    this.props.prevStep();
  }

  handleModifyUsers() {
    let values = this.props.values;
    switch (values.path) {
      case 3:
        this.props.gotoStep(3, 4);
        break;
      case 2:
        this.props.gotoStep(2, 6);
        break;
      case 4:
        this.props.gotoStep(4, 2);
        break;
      default:
        break;
    }
  }

  toDollarAmount(amount) {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const values = this.props.values;
    let fd = formatDate(new Date(), "yyyy-mm-dd HH:MM:ss");
    let users = values.users;
    users.forEach(function (o) {
      delete o["checked"];
    });
    let json = {
      users: users,
      resources: values.resources,
      terms: {
        version: values.agreeTermsVersion,
        selected: fd,
      },
      billing: {
        email: values.billing_email,
        address1: values.billing_address1,
        address2: values.billing_address2,
        city: values.billing_city,
        state: values.billing_state,
        country: values.billing_country,
        zip: values.billing_zip,
        company: values.billing_company,
        token: values.billing_token,
      },
    };
    let result = await api.postBillConfirm(json);
    if (result.status === 200) {
      let date = new Date(result.data.BillingTerms.FirstPayment);
      this.setState({
        billingItems: result.data.BillingTerms.LineItems,
        billingDate: date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "utc",
        }),
        billingTotal: result.data.BillingTerms.Total,
      });
    } else {
      console.log("err req bill");
    }
  }
  render() {
    const {
      values: { path, workspace, resources, crm, users, billing_firstName, billing_lastName, billing_address1, billing_address2, billing_city, billing_state, billing_zip, billing_phone, billing_email, billing_company, billing_token },
    } = this.props;

    return (
      <>
        <div className="flex flex-col flex-grow h-full overflow-auto px-2 py-4 w-full sm:items-center sm:px-12 sm:py-12">
          <div className="m-auto-0 w-full sm:max-w-6xl">
            <h1 className="font-semibold pb-2 text-4xl">Review your order.</h1>
            <p className="mb-9 space-y-4 text-gray-500">
              Please review the information below and confirm. If you have any questions contact{" "}
              <a href="mailto:info@solutionview.com" className="text-blue-500">
                info@solutionview.com
              </a>
            </p>
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              <div className="w-full sm:w-3/5">
                {/* General Info */}
                <h2 className="font-medium mb-2">General Information</h2>
                <div className="border border-gray-300 border-solid mb-8 py-1 rounded-lg">
                  <div className="border-solid divide-gray-300 divide-solid divide-y px-4">
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Workspace</p>
                      <p className="text-right">{workspace}</p>
                    </div>
                    {crm && (
                      <div className="flex justify-between py-2 text-base">
                        <p className="text-gray-500">CRM</p>
                        <p className="capitalize text-right">{crm === "none2" ? "none" : crm}</p>
                      </div>
                    )}
                    {resources.length > 0 && (
                      <div className="flex justify-between py-2 text-base">
                        <p className="text-gray-500">Industry Resources</p>
                        <p className="capitalize text-right">{resources.map((resource) => resource.name).join(", ")}</p>
                      </div>
                    )}
                  </div>
                  <div className="divide-gray-300 divide-solid divide-y px-4">
                    <div className="border-gray-300 border-solid border-t flex justify-between pb-1 pt-3 text-base">
                      <p className="text-gray-500">Licensed Users</p>
                      {/* Redirect to add users screen */}
                      <button
                        className="text-blue-500"
                        onClick={() => {
                          this.handleModifyUsers();
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                  <div className="border-solid divide-gray-300 divide-solid divide-y pl-8 px-4">
                    {users.map((user, index) => (
                      <div key={user.firstName + index}>
                        <div className="flex justify-between text-base">
                          <p className="text-base">
                            {user.firstName} {user.lastName}
                          </p>
                          <p className="text-gray-500 text-sm">{user.email}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <h2 className="font-medium mb-2">Billing Information</h2>
                <div className="border border-gray-300 border-solid mb-8 py-1 rounded-lg">
                  <div className="border-solid divide-gray-300 divide-solid divide-y px-4">
                    <div className="flex justify-between py-1 text-base">
                      <p className="text-gray-500">Credit Card</p>

                      <div className="flex items-center space-x-4">
                        <p className="text-right">**** **** **** {billing_token ? billing_token.card.last4 : "****"}</p>
                        <button
                          className="text-blue-500"
                          onClick={() => {
                            this.handleModifyBilling();
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Company</p>
                      <p className="text-right">{billing_company}</p>
                    </div>
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Name</p>
                      <p className="capitalize text-right">
                        {billing_firstName} {billing_lastName}
                      </p>
                    </div>
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Address</p>
                      <div>
                        <p className="text-right">
                          {billing_address1} {billing_address2}
                        </p>
                        <p className="text-right">
                          {billing_city}, {billing_state} {billing_zip}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Phone</p>
                      <div>
                        <p className="text-right">
                          <a href={`tel:${billing_phone}`}>{billing_phone}</a>
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Email</p>
                      <div>
                        <p className="text-right">
                          <a href={`mailto:${billing_email}`}>{billing_email}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {true ? null:
              <div className="sticky top-0 w-full sm:w-2/5">
                <h2 className="font-medium mb-2">Expected Monthly Balance Due</h2>
                <div className="border border-gray-300 border-solid pt-1 rounded-md">
                  {this.state.billingItems.map((item, idx) => {
                    console.log(item);
                    return (
                      <div className="border-solid divide-gray-300 divide-solid divide-y px-4">
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Description</p>
                          <p className="text-right">{item.Item}</p>
                        </div>
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Quantity</p>
                          <p className="text-right">{item.Quantity}</p>
                        </div>
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Price per license</p>
                          <p className="text-right">{this.toDollarAmount(item.PerItemPrice)}</p>
                        </div>
                      </div>
                    );
                  })}

                  {/* Subtotal  */}
                  <div className="bg-gray-100 border-solid divide-gray-300 divide-solid divide-y px-4 rounded-b-md">
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Subtotal</p>
                      <p className="text-right">{this.toDollarAmount(this.state.billingTotal)}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-base">
                      <p className="text-gray-500">Taxes</p>
                      <p className="text-right text-sm">Invoice may include tax if applicable</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-base">
                      <div className="flex flex-wrap pr-4">
                        <p className="pr-1 text-base text-gray-800">Total due on</p>
                        <p className="font-medium">{this.state.billingDate}</p>
                      </div>
                      <p className="font-medium text-lg text-right">{this.toDollarAmount(this.state.billingTotal)}</p>
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        <FooterNav nextCallback={this.continue(path)} backCallback={this.back} nextDisabled={false} nextText={"Submit Order"} />
      </>
    );
  }
}

const formatDate = (date, format) => {
  return dateFormat(date, format);
};
export default Confirm;
