import { ReactComponent as LogoSVG } from "assets/images/email-illustration.svg";
export const SuccessScreen = ({ values }) => {
  return (
    <>
      <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full sm:py-12 text-center">
        <div className="m-auto-0 w-full sm:max-w-xl">
          <div className="flex items-center justify-center">
            <LogoSVG className="w-48 h-48" />
          </div>
          <h1 className="font-semibold text-3xl mb-2">Check your email.</h1>
          <p className="">An email containing your special access code has been sent to {values.email ? values.email : "your inbox"}.</p>
        </div>
      </div>
    </>
  );
};

export default SuccessScreen;
