import React, { Component } from "react";

import cookies from "../../lib/cookies";
import { LoadingPleaseWait } from "@supportworks/react-components";

import ChoosePath from "./paths/ChoosePath";
import DemoFormResources from "./paths/DemoPath/2_DemoFormResources";
import SuccessScreen from "./paths/DemoPath/3_SuccessScreen";
import UsingSolutionView from "./paths/SignupPath/2_UsingSolutionView";
import LinkSolutionView from "./paths/SignupPath/3a_LinkSolutionView";
import ChooseResources from "./paths/SignupPath/3b_ChooseResources";
import ChooseCRM from "./paths/SignupPath/4_ChooseCRM";
import LinkCRM from "./paths/SignupPath/5_LinkCRM";
import AddUsers from "./paths/SignupPath/6_AddUsers";
import ChooseWorkspace from "./paths/SignupPath/7_ChooseWorkspace";
import AgreeTerms from "./paths/SignupPath/8_AgreeTerms";
import Confirm from "./paths/SignupPath/9_Confirm";
import FinalSubmit from "./paths/SignupPath/10_FinalSubmit";

import { FormpageWrapper } from "..";

const initialState = {
  headerContent: {
    title: "You are on your way to redefining your customer's experience.",
    subtitle: "",
  },
  path: 1,
  step: 1,
  name: "",
  email: "",
  phone: "",
  company: "",
  resources: [],
  workspace: "",
  crm: "",
  users: [],
  agreeTerms: false,
  agreeTermsVersion: 0,
  username: "",
  code: "",
  collect: [],
  branch: "",
  billing_firstName: "",
  billing_lastName: "",
  billing_address1: "",
  billing_address2: "",
  billing_city: "",
  billing_state: "",
  billing_country: "",
  billing_zip: "",
  billing_phone: "",
  billing_email: "",
  billing_company: "",
  billing_token: "",
  password: "",
  context: "",
  progress: 0,
  otherCRM: "",
  otherIndustry: "",
  zapierAd: false,
};

export class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    let urlPath = this.props.match.path;
    if (urlPath.match(/demo/)) {
      this.setState({ path: 1, step: 2 });
    } else if (urlPath.match(/register/)) {
      this.setState({ path: 2, step: 2 });
    } else if (urlPath.match(/workspace/)) {
      this.setState({ path: 3, step: 3 });
      let cookie = cookies.get();
      console.log(cookie);
      this.setState(cookie);
    } else if (urlPath.match(/redirect/)) {
      let cookie = cookies.get();
      console.log(cookie);
      this.setState(cookie);
    }
  }

  // Proceed to next step
  nextStep = async (path) => {
    let step = this.state.step;
    step = step + 1;
    await cookies.set({ ...this.state, step: step, path: path });
    this.setState({
      step: step,
      path: path ? path : 1,
    });
  };

  // Go back to prev step
  prevStep = async () => {
    let { step, path } = this.state;
    step = step - 1;
    if (step === 1) {
      path = 1;
    }

    // if (step === 2 && path > 1) {
    //   path = 2;
    // }

    await cookies.set({ ...this.state, step: step, path: path });
    this.setState({
      step: step,
      path: path,
    });
  };

  // Go to a specific path/step...
  gotoStep = async (path, step) => {
    await cookies.set({ ...this.state, step: step, path: path });
    this.setState({
      step: step,
      path: path ? path : 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    // Special array processing logic
    if (e.target.type === "checkbox") {
      this.setState({
        [input]: !this.state[input],
      });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  setStateParent = async (input, value) => {
    if (typeof input === "object") {
      this.setState(input);
    } else {
      //var clone = _.cloneDeep(value);
      if (input === "clearall") {
        this.setState(initialState);
      }
      this.setState({ [input]: value });
    }
  };

  setCookie = async (input, value) => {
    await cookies.set({ ...this.state, [input]: value });
  };

  handleNonSyntheticEventChange = (input) => (value) => {
    this.setState({ [input]: value });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPleaseWait />;
    }

    let { step, path } = this.state;
    const values = this.state;

    let screen = "";
    let form = "";
    let progress = 0;
    let customProps = {
      nextStep: this.nextStep,
      prevStep: this.prevStep,
      gotoStep: this.gotoStep,
      handleChange: this.handleChange,
      setStateParent: this.setStateParent,
      setCookie: this.setCookie,
      values: values,
    };
    if (path === 1) {
      switch (step) {
        case 1:
          form = <ChoosePath {...customProps} />;
          progress = 12;
          break;
        case 2:
          form = <DemoFormResources {...customProps} />;
          progress = 80;
          break;
        case 3:
          form = <SuccessScreen {...customProps} />;
          progress = 100;
          break;
        default:
          throw new Error("Cannot figure out step.");
      }
    } else if (path === 2) {
      switch (step) {
        case 1:
          screen = <ChoosePath {...customProps} />;
          progress = 10;
          break;
        case 2:
          form = <UsingSolutionView {...customProps} />;
          progress = 12;
          break;
        case 3:
          form = <ChooseResources {...customProps} />;
          progress = 24;

          break;
        case 4:
          form = <ChooseCRM {...customProps} />;
          progress = 36;
          break;
        case 5:
          form = <LinkCRM {...customProps} />;
          progress = 48;
          break;
        case 6:
          form = <AddUsers {...customProps} />;
          progress = 60;
          break;
        case 7:
          form = <ChooseWorkspace {...customProps} />;
          progress = 72;
          break;
        case 8:
          form = <AgreeTerms {...customProps} handleNonSyntheticEventChange={this.handleNonSyntheticEventChange} />;
          progress = 84;
          break;
        case 9:
          form = <Confirm {...customProps} />;
          progress = 96;
          break;
        case 10:
          form = <FinalSubmit {...customProps} />;
          progress = 100;
          break;
        default:
          throw new Error("Cannot figure out step.");
      }
    } else if (path === 3) {
      switch (step) {
        case 3:
          form = <LinkSolutionView {...customProps} />;
          break;
        case 4:
          form = <AddUsers {...customProps} />;
          break;
        case 5:
          form = <AgreeTerms {...customProps} handleNonSyntheticEventChange={this.handleNonSyntheticEventChange} />;
          break;
        case 6:
          form = <Confirm {...customProps} />;

          break;
        case 7:
          form = <FinalSubmit {...customProps} />;
          break;
        default:
          throw new Error("Cannot figure out step.");
      }
    }
    return <FormpageWrapper screen={screen} form={form} progress={progress} headerContent={this.state.headerContent} />;
  }
}

export default UserForm;
