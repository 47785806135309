import React, { Component } from "react";
import LoadingPleaseWait from "@supportworks/react-components/dist/LoadingPleaseWait";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { ReactComponent as LogoSVG } from "assets/images/email-illustration.svg";
import dateFormat from "dateformat";

const api = new APICoreLiteSignup();
export class FinalSubmit extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      code: "",
      data: "",
      errorMessage: "",
      infoMessage: "",
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { values } = this.props;

    if (values.path === 2 || values.path === 3 || values.path === 4) {
      // Emergency shut off valve
      if (!values.agreeTerms) {
        this.props.setStateParent("clearall");
      }

      let fd = formatDate(new Date(), "yyyy-mm-dd HH:MM:ss");
      let users = values.users;
      users.forEach(function (o) {
        delete o["checked"];
      });
      let json = {
        users: users,
        resources: values.resources,
        terms: {
          version: values.agreeTermsVersion,
          selected: fd,
        },
        context: values.workspace,
        billing: {
          email: values.billing_email,
          address1: values.billing_address1,
          address2: values.billing_address2,
          city: values.billing_city,
          state: values.billing_state,
          country: values.billing_country,
          zip: values.billing_zip,
          company: values.billing_company,
          token: values.billing_token,
        },
        feedback: {
          otherIndustry: values.otherIndustry,
          otherCRM: values.otherCRM,
          sendZapier: values.zapierAd,
        },
      };
      console.log(json);
      let result = await api.postConfirm(json);
      if (result.status === 200) {
        this.setState({
          isLoading: false,
          infoMessage: "You have successfully linked to SolutionView.  You may now close this window.",
          data: result.data,
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: "There was an error linking to SolutionView.  You may now close this window.",
          data: result.data,
        });
      }
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="flex h-full items-center justify-center w-full">
          <div className="h-auto">
            <LoadingPleaseWait className="flex flex-col items-center space-y-2" message="Please wait, submitting request." />
          </div>
        </div>
      );
    }

    return (
      <>
        {this.state.errorMessage ? (
          <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 text-center w-full sm:py-12">
            <div className="m-auto-0 w-full sm:max-w-xl">
              <h1 className="bg-red-500 font-semibold mb-2 px-4 py-2 text-3xl text-white">Error</h1>
              <p className="">
                There was an error linking to SolutionView. <br />
                Please contact support by emailing{" "}
                <a href="mailto:info@solutionview.com" className="text-blue-500">
                  info@solutionview.com
                </a>
                .
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 text-center w-full sm:py-12">
              <div className="m-auto-0 w-full sm:max-w-xl">
                <div className="flex items-center justify-center">
                  <LogoSVG className="h-48 w-48" />
                </div>
                <h1 className="font-semibold mb-2 text-3xl">Success!</h1>
                {/* email sent to user email */}
                <p>An email containing your account details has been sent to {this.state.billing_email ? this.state.billing_email : "your billing email."}</p>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
const formatDate = (date, format) => {
  return dateFormat(date, format);
};
export default FinalSubmit;
