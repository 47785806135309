import React, { Component } from "react";
import { Grid, TextField, FormGroup, FormControlLabel, Checkbox, Alert } from "@mui/material";
import { LoadingPleaseWait } from "@supportworks/react-components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import _ from "lodash";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { FooterNav } from "components";
const api = new APICoreLiteSignup();

export class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      users: [],
      errorMessage: "",
      errorMessages: {},
      mode: "add",
      continue: true,
    };
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.checkUser = this.checkUser.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const values = this.props.values;
    if (values.context.createInitialUser === false) {
      if (values.path === 3 || values.path === 2 || values.path === 4) {
        let result = await api.getUsers();
        let users = result.data;
        if (result.status !== 200) {
          if (result.status === 203) {
            this.setState({
              isLoading: false,
              errorMessage: "Your organization is already set up with SolutionView, you do not need to signup.",
              mode: "none",
              continue: false,
            });
            return;
          } else {
            this.setState({
              isLoading: false,
              errorMessage: "We apologize, there was an error.  Please try again later.",
              mode: "check",
            });
            return;
          }
        }
        users = users.map((o) => ({ ...o, createUser: false, checked: false }));
        if (!users) {
          this.setState({
            isLoading: false,
            errorMessage: "Could not load a user list.",
            mode: "check",
          });
          return;
        }

        // If I have anything in props, update to checked
        let u = values.users;
        u.forEach(function (o1) {
          users.forEach(function (o2) {
            if (o1.firstName === o2.firstName && o1.lastName === o2.lastName) {
              o2.checked = true;
            }
          });
        });
        this.setState({ isLoading: false, users: users, mode: "check" });
        return;
      }
    } else {
      this.setState({ isLoading: false, users: values.users, mode: "add" });
      this.addUser(values.firstName, values.lastName, values.username);
    }
  }

  continue = (path) => async (e) => {
    e.preventDefault();
    if (this.state.continue) {
      let users = this.state.users.filter((o) => o.checked === true || o.createUser === true);

      await this.props.setStateParent("users", users);
      this.props.nextStep(path);
    } else {
      this.props.prevStep(path);
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  addUser(firstName, lastName, email) {
    let users = this.state.users;
    users.push({
      firstName: firstName,
      lastName: lastName,
      email: email,
      createUser: true,
    });
    this.setState({ users: users });
  }

  checkUser(idx) {
    let users = this.state.users;
    users[idx].checked = !users[idx].checked;
    this.setState({ users: users });
  }

  deleteUser(idx) {
    let users = this.state.users;
    users.splice(idx, 1);
    this.setState({ users: users });
  }

  render() {
    const { values } = this.props;

    if (this.state.isLoading) {
      return (
        <>
          <div className="flex flex-col flex-grow h-full items-center justify-center overflow-auto px-2 w-full md:py-12">
            <div>
              <LoadingPleaseWait />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full sm:py-12">
          <div className="m-auto-0 w-full sm:max-w-lg">
            <div className="mb-2">
              {this.state.errorMessage ? (
                <>
                  <Alert severity={"error"}>{this.state.errorMessage}</Alert>
                </>
              ) : null}
              <div className="mb-1">
                <h1 className="font-semibold my-4 text-4xl">Add sales users.</h1>
                {this.state.mode === "add" ? (
                  <>
                    <p className="my-2 text-gray-600">To continue, please check at least one user and click Continue.</p>
                    <p className="text-gray-400 text-xs">Note: Users can be added or removed later in the SolutionView Admin.</p>
                    <br />
                    <AddUser onAddUser={this.addUser} />
                  </>
                ) : null}
                {this.state.users.length < 1 ? (
                  <div className="bg-gray-200 border-2 border-dashed border-gray-300 flex flex-col items-center justify-center my-4 p-4 rounded text-gray-600 text-sm w-full">
                    <h1>No users have been added, yet.</h1>
                    <h1>Once added they will show up here.</h1>
                  </div>
                ) : (
                  <h1 className="font-semibold mt-4 text-base">User Names and Emails ({this.state.users.length})</h1>
                )}
                {this.state.mode === "check" ? (
                  <>
                    <p className="my-2 text-gray-600">Please check at least one user for access and click Continue.</p>
                    <p className="text-gray-400 text-xs">Note: Users can be added or removed later in the SolutionView Admin.</p>
                    {/* <AddUser onAddUser={this.addUser} /> */}
                  </>
                ) : null}
              </div>

              {this.state.mode !== "none" ? (
                <FormGroup aria-label="resources" name="resources" value={values.resource}>
                  <UserList users={this.state.users} onDeleteUser={this.deleteUser} onCheckUser={this.checkUser} mode={this.state.mode} />
                </FormGroup>
              ) : null}
            </div>
          </div>
        </div>

        <FooterNav nextCallback={this.continue(values.path)} backCallback={this.back} nextDisabled={this.state.users.length < 1} nextText={this.state.users.length < 1 ? "Skip" : "Continue"} />
      </>
    );
  }
}
class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleDelete(idx) {
    this.props.onDeleteUser(idx);
  }

  handleCheck(idx) {
    this.props.onCheckUser(idx);
  }

  render() {
    let cRef = this;
    return (
      <>
        {this.props.users.length ? (
          <div className="divide-gray-100 divide-solid divide-y even:bg-slate-100 flex flex-col odd:bg-white w-full">
            {this.props.users.map((user, idx) => {
              let checked = user.checked || false;
              return (
                <div
                  className="cursor-pointer flex hover:bg-gray-50 justify-between py-1 w-full"
                  onClick={() => {
                    if (this.props.mode === "check") {
                      cRef.handleCheck(idx);
                    }
                  }}
                  key={idx + "_" + user.firstName + "_" + user.lastName}
                >
                  <div className="flex flex-col pl-2">
                    <h1>
                      {user.firstName} {user.lastName}
                    </h1>
                    <h2 className="text-gray-500 text-sm">{user.userName || user.email}</h2>
                  </div>
                  {this.props.mode === "check" ? (
                    <div className="flex items-center">
                      <FormControlLabel control={<Checkbox checked={checked} name={user.firstName} />} label="" />
                    </div>
                  ) : null}

                  {this.props.mode === "check" ? null : (
                    <div className="flex items-center pr-2">
                      <button
                        onClick={(e) => {
                          cRef.handleDelete(idx);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }
}

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      errorMessages: {},
    };
  }

  handleChange(e) {
    e.preventDefault();

    let errorMessages = {};
    if (!validateEmail(e.target.email.value)) {
      errorMessages["email"] = "Not a valid email.";
    }
    if (!_.isEmpty(errorMessages)) {
      this.setState({ errorMessages: errorMessages });
      return;
    }
    this.props.onAddUser(e.target.firstName.value, e.target.lastName.value, e.target.email.value);
    this.setState({ errorMessages: {} });
  }

  render() {
    return (
      <>
        <form onSubmit={this.handleChange.bind(this)}>
          <Grid container columnSpacing={1}>
            <Grid item sm={6} xs={12}>
              <TextField variant="outlined" name="firstName" id="firstName" label="First Name" margin="normal" fullWidth required />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField variant="outlined" name="lastName" id="lastName" label="Last Name" margin="normal" fullWidth required />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField variant="outlined" name="email" id="email" label="Email" error={this.state.errorMessages.email} helperText={this.state.errorMessages.email} fullWidth margin="normal" required />
            </Grid>

            <Grid item sm={12} xs={12}>
              <button type="submit" className="bg-tertiary-500 flex flex-grow font-semibold hover:bg-tertiary-400 items-center justify-center mt-4 py-1 rounded text-white w-full">
                <div className="flex items-center">
                  <div className="px-1 py-1">
                    <PersonAddIcon />
                  </div>
                  Add User
                </div>
              </button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export default AddUsers;
