export const Hero = ({ title }) => {
  return (
    <div className="bg-hero-image bg-primary-500 filter flex flex-col w-full">
      <div className="flex justify-center w-full">
        <div className="flex max-w-5xl w-full">
          <h1 className="drop-shadow-lg filter p-6 sm:p-8 text-3xl sm:text-5xl text-white">
            {title}
          </h1>
        </div>
      </div>
    </div>
  );
};
export default Hero;
