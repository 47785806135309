import { ArrowForward, ArrowBack } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
export const FooterNav = ({ backCallback, nextCallback, nextDisabled, nextText, backText, errorMessage }) => {
  return (
    <>
      {errorMessage && (
        <div className="bg-red-400 flex px-4 py-1 space-x-2 text-base text-white">
          <ErrorOutlineIcon />
          <p className="text-white">{errorMessage}</p>
        </div>
      )}
      <div className={`flex items-center w-full ${!backCallback ? "justify-end" : "justify-between"} text-sm`}>
        {backCallback && (
          <div className="px-4 py-2 text-blue-500">
            <button className="border border-gray-300 border-solid flex hover:bg-gray-100 items-center px-3 py-2 rounded space-x-2 sm:px-4 sm:py-2" onClick={backCallback}>
              <ArrowBack />
              <h1 className="text-blue-500">{backText || "Back"}</h1>
            </button>
          </div>
        )}
        {nextCallback && (
          <div className="px-4 py-2">
            <button className={`flex items-center px-3 sm:px-4 py-2 sm:py-2 rounded space-x-2 transition duration-75 ${nextDisabled ? "bg-gray-200 text-gray-800" : "text-white bg-primary-500 hover:bg-primary-600"}`} onClick={nextCallback}>
              <p className={`${nextDisabled ? "text-black" : "text-white"}`}>{nextText || "Continue"}</p>
              <ArrowForward />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FooterNav;
