import React from "react";
import { Container } from "@mui/material";
import Hero from "./Hero";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, info) {
    // Put the Core API here to report errors
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorStack: info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container className="container">
          <Hero title={"Application Error"} path={0} step={0} />
          <div className={"content"}>
            <p>We apologize, an error has occurred. Please try again later.</p>
          </div>
        </Container>
      );
    }
    return this.props.children;
  }
}
