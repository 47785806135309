import React, { Component } from "react";
import { TextField } from "@mui/material";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { FooterNav } from "components";
import cookies from "lib/cookies";
import _ from "lodash";

const api = new APICoreLiteSignup();

export class ChooseCRM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      context: "",
      errorMessage: "",
      errorMessages: {},
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    // let values = this.props.values;
    let c = cookies.get();
    let context = c.context;
    if (!context) {
      this.setState({
        errorMessage: "We have encountered an error, please try again.",
      });
      return null;
    }

    // If I need to collect stuff, I want to display this page for entry.
    if (context.collectUsername === true || context.collect.length > 0) {
      this.setState({ isLoading: false, context: context });
      return null;
    }
    // Otherwise, just automatically redirect using the create API.
    this.redirect();
  }

  continue = (path) => async (e) => {
    e.preventDefault();

    const { values } = this.props;

    let errorMessage = "";
    let errorMessages = {};
    let c = cookies.get();
    let context = c.context;
    let payload = [];

    // Validation for collectUsername stuff...
    if (context.collectUsername === true) {
      if (!values.username) {
        errorMessages["username"] = "Missing username.";
      }

      if (!values.password) {
        errorMessages["password"] = "Missing password.";
      }
      c.username = values.username;
      payload["username"] = values.username;
      payload["password"] = values.password;
    }

    // Validation for createInitialUser path...
    if (context.createInitialUser === true) {
      if (values.password !== values.password2) {
        errorMessage = "Passwords do not match.";
        errorMessages["password2"] = "Passwords do not match.";
      }
      if (!values.password2) {
        errorMessage = "Missing password.";
        errorMessages["password2"] = "Please re-enter password.";
      }
      if (!validateEmail(values.username)) {
        errorMessage = "Please enter a valid email address as your username.";
        errorMessages["username"] = "Please enter a valid email address as your username.";
      }
    }

    // Validation for things we want to "collect" based on context data...
    if (context.collect.length > 0) {
      context.collect.forEach((item) => {
        if (!values[item.id]) {
          errorMessages[item.id] = "Missing value.";
        } else {
          payload[item.id] = values[item.id];
          c[item.id] = values[item.id];
        }
      });
    }

    if (!_.isEmpty(errorMessages)) {
      this.setState({
        errorMessage: errorMessage,
        errorMessages: errorMessages,
      });
      return;
    }

    // We made it!!! Go to redirect for the fireworks...
    cookies.set(c);
    this.redirect(payload);
  };

  redirect = async (payload = []) => {
    let values = this.props.values;

    let c = cookies.get();
    let context = c.context;
    c.path = values.path;
    c.step = values.step + 1;
    cookies.set(c);
    try {
      payload["redirectUrl"] = "http://" + window.location.host + "/redirect";
      console.log(payload);
      let result = await api.create(c.crm, payload);

      if (result.status === 200) {
        this.props.nextStep(values.path);
      } else {
        this.setState({
          errorMessage: "We were unable to authenticate, try again.",
        });
        return;
      }
    } catch (err) {
      if (context.collectUsername === true) {
        this.setState({
          errorMessage: "There was an error logging in. Please try again.",
        });
      }
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values } = this.props;

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full sm:py-12">
          <div className="m-auto-0 w-full sm:max-w-lg">
            <div className="mb-2">
              <div className="my-2 sm:my-8">
                <h1 className="font-semibold mt-16 pb-1 text-2xl sm:pb-4 sm:text-4xl">Create your account.</h1>
                <p className="mb-4 text-base text-gray-600 sm:mb-8">Please fill out the form below to create your account.</p>
              </div>

              {/* form */}
              {values.context.collectUsername ? (
                <UsernamePassword
                  username={this.state.username}
                  usernameLabel={values.context.createInitialUser ? "Enter your email (this will be your username)" : "Enter your username"}
                  password={this.state.password}
                  errorMessages={this.state.errorMessages}
                  handleChange={this.props.handleChange}
                  variant="filled"
                  showPassword2={values.context.createInitialUser ? true : false}
                />
              ) : null}
              {values.context.collect.map((item) => (
                <TextField
                  key={"COLLECT" + item.id}
                  name={item.id}
                  label={item.label}
                  onChange={this.props.handleChange(item.id)}
                  defaultValue={values[item.id]}
                  margin="normal"
                  variant="filled"
                  fullWidth
                  error={this.state.errorMessages[item.id] ? true : false}
                  helperText={this.state.errorMessages[item.id]}
                />
              ))}
            </div>
          </div>
        </div>
        <FooterNav nextCallback={this.continue(values.path)} backCallback={this.back} nextDisabled={false} errorMessage={this.state.errorMessage} />
      </>
    );
  }
}
class UsernamePassword extends React.Component {
  render() {
    let props = this.props;
    return (
      <>
        <TextField
          name="username"
          placeholder="Email"
          label={props.usernameLabel}
          onChange={props.handleChange("username")}
          defaultValue={props.username}
          margin="normal"
          variant="filled"
          error={props.errorMessages.username ? true : false}
          helperText={props.errorMessages.username}
          fullWidth
          required
        />
        <TextField
          name="password"
          type="password"
          placeholder="Enter password"
          label="Password"
          onChange={props.handleChange("password")}
          defaultValue={props.password}
          margin="normal"
          variant="filled"
          error={props.errorMessages.password ? true : false}
          helperText={props.errorMessages.password}
          fullWidth
          required
          autoComplete="off"
        />
        {props.showPassword2 ? (
          <TextField
            name="password2"
            type="password"
            placeholder="Please repeat your password."
            label="Repeat password"
            onChange={props.handleChange("password2")}
            defaultValue={props.password2}
            margin="normal"
            variant="filled"
            error={props.errorMessages.password2 ? true : false}
            helperText={props.errorMessages.password2}
            autoComplete="off"
            fullWidth
            required
          />
        ) : null}
      </>
    );
  }
}
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export default ChooseCRM;
