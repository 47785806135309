/* eslint-disable react-hooks/exhaustive-deps */
import { FooterNav, HelpBubble } from "../../..";
import { Check, HourglassEmpty, Error } from "@mui/icons-material";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";

const api = new APICoreLiteSignup();

const ChooseWorkspace = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validWorkspace, setValidWorkspace] = useState(false);
  const [error, setError] = useState("");
  const [workspace, setWorkspace] = useState(props.values.workspace);
  const [open, setOpen] = useState(false);
  const [workspaceResultsCache, setWorkspaceResultsCache] = useState([]);
  const debouncedWorkspace = useDebounce(workspace, 500);

  const isMounted = useRef(false);

  useEffect(() => {
    if (debouncedWorkspace) {
      submitWorkspace(false);
    } else {
      setIsLoading(false);
      setValidWorkspace(false);
      setError("Please enter a workspace name.");
    }
  }, [debouncedWorkspace]);

  useEffect(() => {
    if (isMounted.current) {
      // check if workspace is in cache
      if (workspaceResultsCache.length > 0 && workspaceResultsCache.find((o) => o.workspace === workspace)) {
        workspaceResultsCache.forEach((o) => {
          if (o.workspace === workspace) {
            setValidWorkspace(o.valid);
            setError(o.error);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(true);
        setError("");
      }
    } else {
      isMounted.current = true;
    }
  }, [workspace]);

  useEffect(() => {
    if (!props.values.workspace) {
      setWorkspace(scrubWorkspace(props.values.billing_company));
      props.setStateParent("workspace", scrubWorkspace(props.values.billing_company));
    }
  }, []);

  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const scrubWorkspace = (workspace) => {
    return workspace.replace(/[^A-Z0-9]/gi, "").toLowerCase();
  };
  const handleWorkspaceChange = (value) => {
    const scrub = scrubWorkspace(value);
    props.setStateParent("workspace", scrub);
    setError("");
    setIsLoading(true);
    setValidWorkspace(false);
    setWorkspace(scrub);
  };

  const submitWorkspace = async (claim) => {
    if (claim) {
      // next step
      if (validWorkspace) {
        props.nextStep(props.values.path);
      }
    } else {
      setValidWorkspace(false);
      setIsLoading(true);

      let stringClaim = claim ? "true" : "false";
      // check cache for workspace results or submit new request

      //if workspace is in cache, use cache
      if (workspaceResultsCache.length > 0 && workspaceResultsCache.find((o) => o.workspace === workspace)) {
        workspaceResultsCache.forEach((o) => {
          if (o.workspace === workspace) {
            setValidWorkspace(o.valid);
            setError(o.error);
            setIsLoading(false);
          }
        });
      } else {
        let result = await api.isWorkspaceAvailable(workspace, stringClaim);
        switch (result.status) {
          case 200:
            setError("");
            setValidWorkspace(true);
            setIsLoading(false);
            // add to workspace results cache
            let cache = workspaceResultsCache;
            cache.push({
              workspace: workspace,
              valid: true,
              error: "",
            });
            break;
          case 204:
            setError("That workspace name is not available, please try again.");
            setIsLoading(false);
            setValidWorkspace(false);
            // add to workspace results cache
            let newCache = workspaceResultsCache;
            newCache.push({
              workspace: workspace,
              valid: false,
              error: "That workspace name is not available, please try again.",
            });
            setWorkspaceResultsCache(newCache);
            break;
          default:
            setError("There was an error checking that workspace name, please try again.");
            setIsLoading(false);
            setValidWorkspace(false);
        }
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-end mr-4 py-4">
        <HelpBubble
          title={"What is a Workspace?"}
          content={[
            "A workspace (or an organizational ID) isn't new and is common in software services provided to businesses.",
            "Your workspace will be unique to your organization, and it helps streamline your login process. Plus, if you happen to have individuals that work for multiple organizations, it allows them to use a single email address to log into multiple instances of SolutionView without disruption.",
          ]}
          open={open}
          setOpen={setOpen}
        />
      </div>

      <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full sm:py-12">
        <div className="m-auto-0 w-full sm:max-w-xl">
          <div className="flex items-center space-x-2">
            <h1 className="font-semibold my-2 text-2xl sm:text-4xl">Create a new Workspace.</h1>
          </div>

          <p className="mb-2 text-gray-600">
            Each time you log into the SolutionView App or SolutionView Admin, you'll need to enter your{" "}
            <span className="cursor-pointer underline" onClick={(e) => setOpen(true)}>
              Workspace
            </span>{" "}
            name.
          </p>
          <div className="flex flex-col flex-grow items-center mt-4 md:space-x-4">
            <TextField
              name="workspace"
              placeholder="Enter Workspace"
              label="Workspace"
              onChange={(e) => handleWorkspaceChange(e.target.value)}
              defaultValue={props.values.workspace ? props.values.workspace : scrubWorkspace(props.values.billing_company)}
              value={workspace}
              margin="normal"
              fullWidth
              error={error !== ""}
              helperText={error ? error : validWorkspace ? "Valid Workspace!" : isLoading ? "Checking workspace..." : ""}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton tabIndex={-1}>
                      {isLoading ? (
                        <HourglassEmpty />
                      ) : (
                        <>
                          {validWorkspace ? <Check /> : null}
                          {error ? <Error /> : null}
                        </>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      <FooterNav nextCallback={(e) => submitWorkspace(true)} backCallback={back} nextDisabled={!validWorkspace || isLoading} />
    </>
  );
};
const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};
export default ChooseWorkspace;
