import React, { Component } from "react";
import { FooterNav } from "components";
import TitleSubtile from "components/TitleSubtile";
export class UsingSolutionView extends Component {
  continue = (path) => (e) => {
    e.preventDefault();
    this.props.nextStep(path);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    /*
    <button className="border border-solid hover:bg-secondary-100 hover:text-primary-500 py-2 rounded-xl shadow-sm text-3xl w-full sm:py-8" onClick={this.continue(3)}>
      Yes
    </button>
    */

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 py-4 w-full sm:py-12">
          <div className="m-auto-0 w-full md:max-w-2xl">
            <TitleSubtile title="Is your organization already using SolutionView Tablet?" />
            <div className="flex flex-col items-center justify-center mt-4 space-y-4 sm:mt-16 sm:space-y-0 md:flex-row md:space-x-4">
              <button className="border border-solid hover:bg-secondary-100 hover:text-primary-500 py-2 rounded-xl shadow-sm text-3xl w-full sm:py-8" onClick={this.continue(2)}>
                No
              </button>
            </div>
          </div>
        </div>
        <FooterNav backCallback={this.back} nextDisabled={true} />
      </>
    );
  }
}

export default UsingSolutionView;
