import { ReactComponent as LogoSVG } from "../assets/images/SolutionView-Dark.svg";

export const MobileHeader = () => {
  return (
    <>
      <div className="border-b border-gray-300 border-solid flex justify-between py-2 sm:hidden">
        <div className="flex h-12 items-start justify-start w-56">
          <LogoSVG />
        </div>
      </div>
    </>
  );
};
export default MobileHeader;
