import Cookies from "universal-cookie";
const cookies = new Cookies();

const SW_COOKIE_NAME = "swsup";

function get() {
  return cookies.get(SW_COOKIE_NAME);
}

async function set(values) {
  delete values.password;
  delete values.password2;
  let expDate = new Date();
  expDate.setDate(expDate.getDate() + 7); // 7-day expiration on cookie;
  cookies.set(SW_COOKIE_NAME, values, { expires: expDate });
}

const exportedAll = {
  get,
  set,
};
export default exportedAll;
