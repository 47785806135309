import { useEffect } from "react";
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, useMediaQuery, useTheme } from "@mui/material";

function BasicDialog({ toggleCallback, agreeCallback, content, open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // UseEffect upon toggle of the modal so we can tell the parent component that the modal is open or closed
  useEffect(() => {
    if (toggleCallback) {
      toggleCallback(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <p>
        I've read and accept the{" "}
        <span
          className="cursor-pointer text-blue-500 underline"
          onClick={() => {
            setOpen(true);
          }}
        >
          Terms & Conditions
        </span>
        .*
      </p>
      <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"} aria-labelledby="terms-of-service-dialog-title" aria-describedby="terms-of-service-dialog" fullScreen={fullScreen}>
        <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            //ref={descriptionElementRef}
            tabIndex={-1}
            dangerouslySetInnerHTML={{ __html: content }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              agreeCallback(false);
              toggleCallback(true);
            }}
          >
            Disagree
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              agreeCallback(true);
              toggleCallback(true);
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BasicDialog;
