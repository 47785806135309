import React, { Component } from "react";
import { RadioGroup, FormControlLabel, Radio, TextField, Checkbox } from "@mui/material";
import { LoadingPleaseWait } from "@supportworks/react-components";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { FooterNav } from "components";
import zapierLogo from "assets/images/zapier-logo.png";

const api = new APICoreLiteSignup();

export class ChooseCRM extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      crms: [],
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    api
      .getCRMs()
      .then((result) => {
        if (result.status === 200) {
          this.setState({ crms: result.data, isLoading: false });
          // console.log(result.data);
        } else {
          this.setState({
            isLoading: false,
            errorMessage: "We apologize, there was an error.",
          });
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        this.setState({
          isLoading: false,
          errorMessage: "We apologize, there was an error.",
        });
      });
  }

  continue = (path) => async (e) => {
    e.preventDefault();
    let errorMessage;
    if (!this.props.values.crm) {
      errorMessage = "Select which CRM you use.";
    }
    if (errorMessage) {
      this.setState({ errorMessage: errorMessage });
    } else {
      const context = this.state.crms.find((x) => x.id === this.props.values.crm);
      await this.props.setStateParent("context", context);
      this.props.nextStep(path);
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center justify-center overflow-auto px-2 w-full md:py-12">
          {this.state.isLoading ? (
            <div>
              <LoadingPleaseWait />
            </div>
          ) : (
            <>
              <div className="m-auto-0 w-full md:max-w-xl">
                <h1 className="font-semibold mt-16 pb-1 text-2xl sm:pb-4 sm:text-4xl">Select your CRM.</h1>
                <p className="mb-4 text-base text-gray-600 sm:mb-8">By selecting a CRM, all your employees and products will be synced into SolutionView.</p>
                <RadioGroup aria-label="crm" name="crm" value={values.crm} onChange={handleChange("crm")}>
                  {this.state.crms.map((item) => {
                    console.log(item);
                    return (
                      <div key={"key" + item.id} className={`${item.id === values.crm ? "border-primary-300 bg-secondary-100" : "bg-gray-50 hover:bg-gray-100 "} w-full border border-solid rounded border-gray-300 my-1 text-gray-600 font-normal transition duration-75`}>
                        <FormControlLabel
                          value={item.id}
                          key={item.id}
                          id={item.id}
                          control={<Radio />}
                          label={item.name}
                          style={{
                            width: 100.0 + "%",
                            margin: "0px",
                          }}
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
                {values.crm === "none2" && (
                  <>
                    <h1 className="font-bold mt-4 py-1 text-sm">Are you using a CRM not listed here?</h1>
                    <p className="text-gray-600 text-sm">Please enter it below, and we'll use your feedback to help prioritize which CRM we integrate with next.</p>
                    <TextField variant="outlined" defaultValue={values.otherCRM} onChange={handleChange("otherCRM")} name="other_crm" id="other_crm" label="Enter your CRM" fullWidth margin="normal" />
                  </>
                )}
                {/* Zapier Ad */}

                <div className="border border-solid border-vermilion flex flex-col mt-4 rounded-lg w-full">
                  <div className="flex items-center">
                    <div className="p-4 w-1/4">
                      {/* Zapier Logo */}
                      <img src={zapierLogo} alt="zapier logo" />
                    </div>
                    <div className="w-3/4">
                      <p className="font-medium text-xs sm:text-base">Use Zapier to connect SolutionView with over 2,000 websites and apps.</p>
                    </div>
                  </div>
                  <div className="border-gray-200 border-solid border-t flex items-center px-2">
                    <Checkbox
                      size="small"
                      checked={values.zapierAd}
                      sx={{
                        color: "#D33A00",
                        "&.Mui-checked": {
                          color: "#FF6D35",
                        },
                      }}
                      onChange={handleChange("zapierAd")}
                    />
                    <p className="text-gray-500 text-sm">Send me more information about Zapier</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <FooterNav nextCallback={this.continue(2)} backCallback={this.back} nextDisabled={!values.crm} errorMessage={this.state.errorMessage} />
      </>
    );
  }
}

export default ChooseCRM;
