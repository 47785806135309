import { ReactComponent as LogoSVG } from "../assets/images/SolutionView-Dark.svg";
import { useState, useEffect } from "react";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { Dialog, DialogTitle, DialogContentText, DialogContent } from "@mui/material";
import preval from "preval.macro";

export const Hero = ({ title, subtitle, p }) => {
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState();

  useEffect(() => {
    const api = new APICoreLiteSignup();
    api.getTerms().then((result) => {
      if (result.status === 200) {
        setTerms(result.data.content);
      }
    });
  }, []);

  return (
    <div className="bg-secondary-100 flex-col hidden justify-center p-4 sm:flex sm:text-left sm:w-504 lg:px-8">
      <div className="flex flex-col flex-grow justify-start space-y-8 w-full">
        <div className="mt-4 w-52">
          <a href="https://solutionview.com">
            <LogoSVG />
          </a>
        </div>
        <div className="pr-8 space-y-4 text-center sm:pl-3 sm:text-3xl sm:text-left">
          <h4 className="font-bold text-2xl text-primary-500">{title ? title : "You're one step closer to redefining your customer's experience."}</h4>
          <h4 className="font-normal text-primary-500">{subtitle ? subtitle : ""}</h4>
          <p className="font-light text-primary-500">{p}</p>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        {/* Copyright */}
        <div>
          <span className="font-normal text-gray-600 text-xs">
            © {new Date().getFullYear()} SolutionView. All rights reserved. <br></br>Build Time: {preval`module.exports = new Date().toLocaleString();`}.
          </span>
        </div>
        {/* Terms */}
        <div>
          <span onClick={(e) => setOpen(true)} className="cursor-pointer font-normal text-gray-500 text-xs underline">
            Terms Apply
          </span>
          <Dialog open={open} onClose={() => setOpen(false)} scroll={"paper"} aria-labelledby="terms-of-service-dialog-title" aria-describedby="terms-of-service-dialog" fullScreen={false}>
            <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                //ref={descriptionElementRef}
                tabIndex={-1}
                dangerouslySetInnerHTML={{ __html: terms }}
              ></DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default Hero;
