const TitleSubtile = ({ title, subtitle }) => {
  return (
    <>
      <div className="flex items-center space-x-2">
        <h1 className="font-semibold my-2 text-2xl sm:text-4xl">{title ? title : ""}</h1>
      </div>
    
      <p className="mb-2 text-gray-600">{subtitle ? subtitle : ""}</p>
    </>
  );
};

export default TitleSubtile;
