/* eslint-disable react-hooks/exhaustive-deps */
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";

const StripeForm = ({ handleStripeError, handleStripeToken, enabledFormEntry }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [cardValidated, setCardValidated] = useState(false);

  useEffect(() => {
    if (cardComplete) {
      handleSubmit();
    }
    console.log("[check card]");
  }, [cardComplete]);

  useEffect(() => {
    if (cardValidated && enabledFormEntry) {
      setCardValidated(false);
      setCardComplete(false);
    }
  }, [enabledFormEntry]);

  const handleSubmit = async (event) => {
    setCardValidated(true);
    // Block native form submission.
    if (event) {
      event.preventDefault();
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
      setCardValidated(false);
      await handleStripeError(error);
      return;
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const token = await stripe.createToken(cardElement);
      setCardValidated(true);
      await handleStripeToken(token);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                padding: "0px",
                "::placeholder": {
                  color: "#666666",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
            disabled: cardValidated,
          }}
          onChange={(e) => {
            setCardComplete(e.complete);
          }}
        />
      </form>
    </>
  );
};
export default StripeForm;
