import AnnouncementIcon from "@mui/icons-material/Announcement";
export const InfoText = ({ text }) => {
  return (
    <div className="bg-secondary-200 flex items-center my-2 rounded">
      <div className="p-4">
        <AnnouncementIcon className="text-secondary-500" />
      </div>
      <div className="pr-2">
        <p className="py-2 text-sm">{text}</p>
      </div>
    </div>
  );
};

export default InfoText;
