import { TextField, Radio } from "@mui/material";
import { PhoneInput } from "@supportworks/react-components-input";
import { useState, useEffect } from "react";
import { TestDriveValidation } from "../../../validation/TestDriveValidation";
import { useFormik } from "formik";
import { FooterNav } from "../..";

export const ChoosePath = ({ values, setStateParent }) => {
  const [demoPath, toggleDemo] = useState("");
  useEffect(() => {
    (() => formik.validateForm())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTestDriveSubmit = async (values) => {
    setStateParent("name", values.firstname);
    setStateParent("email", values.email);
    setStateParent("phone", values.phone);
    setStateParent("company", values.company);
    setStateParent("path", 1);
    setStateParent("step", 2);
  };

  const handleSignupSubmit = async (values) => {
    setStateParent("billing_firstName", values.firstname);
    setStateParent("billing_lastName", values.lastname);
    setStateParent("billing_email", values.email);
    setStateParent("billing_phone", values.phone);
    setStateParent("billing_company", values.company);
    setStateParent("path", 2);
    setStateParent("step", 2);
  };

  const formik = useFormik({
    initialValues: {
      firstname: values.billing_firstName ? values.billing_firstName : "",
      lastname: values.billing_lastName ? values.billing_lastName : "",
      email: values.billing_email ? values.billing_email : "",
      company: values.billing_company ? values.billing_company : "",
      phone: values.billing_phone ? values.billing_phone : "",
    },
    validationSchema: TestDriveValidation,
    onSubmit: (values) => {
      if (demoPath === true) {
        handleTestDriveSubmit(values);
      } else if (demoPath === false) {
        handleSignupSubmit(values);
      } else {
        console.log("error");
        formik.setSubmitting(false);

        // no path selected
      }
    },
  });

  return (
    <>
      <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 py-4 w-full sm:py-12">
        <div className="m-auto-0 w-full sm:max-w-lg">
          <div className="mb-2">
            <h1 className="font-bold mb-2 text-4xl">Let's get started.</h1>
            <p className="mb-4 text-gray-600 sm:mb-10">Please provide your contact information below.</p>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {/* Split horizontally */}
              <div className="flex flex-col sm:flex-row sm:space-x-2">
                <div className="flex flex-col w-full sm:w-1/2">
                  <TextField
                    name="firstname"
                    placeholder="Johnny"
                    label="Firstname"
                    margin="normal"
                    fullWidth
                    inputProps={{ "aria-label": "firstname" }}
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                    helperText={formik.touched.firstname && formik.errors.firstname}
                    onBlur={formik.handleBlur}
                    required
                    disabled={formik.isSubmitting}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-1/2">
                  <TextField
                    name="lastname"
                    placeholder="Appleseed"
                    label="Lastname"
                    margin="normal"
                    fullWidth
                    inputProps={{ "aria-label": "lastname" }}
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                    helperText={formik.touched.lastname && formik.errors.lastname}
                    onBlur={formik.handleBlur}
                    required
                    disabled={formik.isSubmitting}
                    defaultValue={formik.values.lastname}
                  />
                </div>
              </div>

              <TextField
                name="email"
                placeholder="johnny@appleseed.com"
                label="Email"
                margin="normal"
                required
                fullWidth
                inputProps={{ "aria-label": "Email" }}
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value.trim());
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
              />
              <br />
              <TextField
                name="company"
                required
                placeholder="Company Inc"
                label="Company"
                margin="normal"
                fullWidth
                inputProps={{ "aria-label": "Company" }}
                value={formik.values.company}
                onChange={formik.handleChange}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
              />
              <div className="">
                <PhoneInput id="phone" name="phone" fullWidth required value={formik.values.phone} onChange={formik.handleChange} error={formik.touched.phone && formik.errors.phone} onBlur={formik.handleBlur} disabled={formik.isSubmitting} />
              </div>
            </form>
            {/* Checkboxes pick path */}
            <div className="mt-12 space-y-4">
              {true?null:<h2 className="font-bold mb-6">Select which option is right for you.</h2>}
              <div
                className={`tranition duration-75 flex items-center border-solid rounded-lg px-4 py-6 cursor-pointer ${demoPath === false ? "bg-blue-100 outline outline-2 outline-blue-600" : "outline-gray-300 outline-1 outline hover:outline-blue-500 hover:bg-blue-50 "} `}
                onClick={(e) => toggleDemo(false)}
              >
                <div className="mr-4">
                  <Radio color="primary" checked={demoPath === false} name="!demoPath" disableRipple={true} />
                </div>
                <div>
                  <h3 className="mb-2 text-base">Ready to go live with SolutionView?</h3>
                  <p className="text-gray-500 text-xs">We won't bill you for the first 14 days after you set up your new account, and you can opt-out at any time during that period without being billed.</p>
                </div>
              </div>
              {true?null:
              <div
                className={`tranition duration-75 flex items-center border-solid rounded-lg px-4 py-6 cursor-pointer ${demoPath === true ? "bg-blue-100 outline outline-2 outline-blue-600" : "outline-gray-300 outline-1 outline hover:outline-blue-500 hover:bg-blue-50 "} `}
                onClick={(e) => toggleDemo(true)}
              >
                <div className="mr-4">
                  <Radio color="primary" checked={demoPath === true} name="demoPath" disableRipple={true} />
                </div>
                <div>
                  <h3 className="mb-2 text-base">Try before you buy!</h3>
                  <p className="text-gray-500 text-xs">We have demos available right in the app, so you can really get a feel for how SolutionView can take your company to the next level.</p>
                  <br />
                  <p className="text-gray-500 text-xs">Once you're ready to go live, we'll be ready to help you get started.</p>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <FooterNav nextCallback={(e) => formik.submitForm()} nextDisabled={!formik.isValid || formik.isSubmitting || demoPath === ""} />
    </>
  );
};

export default ChoosePath;
