import React, { Component } from "react";
import { Grid, Checkbox, TextField, Alert } from "@mui/material";
import StripeForm from "../../../StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import { LoadingPleaseWait } from "@supportworks/react-components";
import { PhoneInput, Address } from "@supportworks/react-components-input";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { BasicDialog, FooterNav } from "../../..";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { ReactComponent as PoweredByStripe } from "assets/images/powered_by_stripe_black.svg";
import { InfoText } from "components/InfoText";

const api = new APICoreLiteSignup();

const stripePK = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripePK);

export class AgreeTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      terms: {},
      didViewTerms: false,
      showStripeComponent: true,
      errorMessage: "",
      errorMessages: {},
      billingItems: [],
      billingDate: "",
      billingTotal: "",
      open: false,
    };
    this.toggleStripeComponent = this.toggleStripeComponent.bind(this);
  }
  toDollarAmount(amount) {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  componentDidMount() {
    this.setState({ isLoading: true, didViewTerms: false });
    api
      .getTerms()
      .then((result) => {
        if (result.status === 200) {
          this.setState({ terms: result.data, isLoading: false });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: "We apologize, there was an error.",
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorMessage: "We apologize, there was an error.",
        });
      });
    const values = this.props.values;
    let users = values.users;
    users.forEach(function (o) {
      delete o["checked"];
    });

    let json = {
      users: users,
      resources: values.resources,
      billing: {},
    };
    api.postBillConfirm(json).then((result) => {
      if (result.status === 200) {
        let date = new Date(result.data.BillingTerms.FirstPayment);

        this.setState({
          billingItems: result.data.BillingTerms.LineItems,
          billingDate: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "utc",
          }),
          billingTotal: result.data.BillingTerms.Total,
        });
      } else {
        console.log("err req bill");
      }
    });
  }

  setOpen = (open) => {
    this.setState({ open });
  };

  continue = (path) => async (e) => {
    e.preventDefault();

    let errorMessage;
    let errorMessages = {};

    const { values } = this.props;

    if (!values.agreeTerms) {
      errorMessages["agreeTerms"] = "You must agree to terms to proceed.";
    }
    if (!values.billing_company) {
      errorMessages["billing_company"] = "Please enter a company name.";
    }
    if (!values.billing_firstName) {
      errorMessages["billing_firstName"] = "Please enter your first name.";
    }
    if (!values.billing_lastName) {
      errorMessages["billing_lastName"] = "Please enter your last name.";
    }
    if (!values.billing_address1) {
      errorMessages["billing_address1"] = "Please enter the address.";
    }
    if (!values.billing_city) {
      errorMessages["billing_city"] = "Please enter the city.";
    }
    if (values.billing_country.match(/US|CA/) && !values.billing_state) {
      errorMessages["billing_state"] = "Please select your state or province.";
    }
    if (!values.billing_zip) {
      errorMessages["billing_zip"] = "Please enter your zip or postal code.";
    }
    if (!values.billing_country) {
      errorMessages["billing_country"] = "Please select your country.";
    }
    if (!values.billing_phone) {
      errorMessages["billing_phone"] = "Please provide a telephone number.";
    }

    if (!values.billing_email) {
      errorMessages["billing_email"] = "Please enter your email address.";
    }
    if (!validateEmail(values.billing_email)) {
      errorMessages["billing_email"] = "Please enter a valid email address.";
    }
    if (!values.billing_token) {
      errorMessages["billing_token"] = "Please provide a valid credit card.";
    }
    if (!validateEmail(values.billing_email)) {
      errorMessages["email"] = "Please enter a valid email address.";
    }
    if (!_.isEmpty(errorMessages) || errorMessage) {
      this.setState({
        errorMessages: errorMessages,
        errorMessage: errorMessage,
      });
      return;
    }
    await this.props.setStateParent("agreeTermsVersion", this.state.terms.version);
    this.props.nextStep(path);
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleStripeToken = async (data) => {
    if (data && data.token) {
      await this.props.setStateParent("billing_token", data.token);
      let errorMessages = this.state.errorMessages;
      if (errorMessages["billing_token"]) {
        delete errorMessages["billing_token"];
      }
      this.setState({
        errorMessages: errorMessages,
        showStripeComponent: false,
      });
    } else {
      this.setState({
        errorMessage: "We have encountered an error validating your credit card information.",
      });
    }
  };

  handleChangeAddress = (address) => {
    this.props.setStateParent("billing_zip", address.zip);
    this.props.setStateParent("billing_address1", address.address1);
    this.props.setStateParent("billing_address2", address.address2);
    this.props.setStateParent("billing_city", address.city);
    this.props.setStateParent("billing_state", address.state);
    this.props.setStateParent("billing_country", address.country);
  };

  handleStripeError = async (error) => {
    this.setState({ errorMessage: error.message });
  };

  toggleStripeComponent = async () => {
    await this.props.setStateParent("billing_token", "");
    this.setState((prevState) => ({
      showStripeComponent: !prevState.showStripeComponent,
    }));
  };

  render() {
    const { values, handleChange } = this.props;
    //const { values, handleChange, handleNonSyntheticEventChange } = this.props;

    return (
      <>
        <div className="flex flex-col flex-grow h-full items-center jusif overflow-auto px-2 py-4 w-full sm:py-12">
          {this.state.isLoading ? (
            <div>
              <LoadingPleaseWait />
            </div>
          ) : (
            <div className="m-auto-0 w-full sm:max-w-lg">
              <h1 className="font-semibold pb-2 text-3xl sm:pb-3">Finalize company and billing info.</h1>
              <p className="mb-9 text-gray-500">Please review your contact information and our terms of service below.</p>
              <div className="mb-2">
                {this.state.errorMessage ? (
                  <>
                    <Alert severity={"error"}>{this.state.errorMessage}</Alert>
                    <br />
                  </>
                ) : null}
                {this.state.terms.content ? (
                  <div className="mb-4 mt-4">
                    <h3 className="font-medium">Review Our Terms of Service</h3>
                    <div className="flex items-center">
                      <Checkbox
                        disableRipple={true}
                        sx={{
                          paddingLeft: "0",
                        }}
                        checked={values.agreeTerms}
                        onChange={this.state.didViewTerms ? handleChange("agreeTerms") : null}
                        readOnly={!this.state.didViewTerms && !values.agreeTerms}
                        id={"agreeTerms"}
                        name={"agreeTerms"}
                        onClick={
                          !this.state.didViewTerms && !values.agreeTerms
                            ? () => {
                                this.setOpen(true);
                              }
                            : null
                        }
                      />

                      <BasicDialog
                        buttonText={"View Terms"}
                        title={"SolutionView Terms & Conditions"}
                        open={this.state.open}
                        setOpen={() => {
                          this.setOpen(!this.state.open);
                        }}
                        content={this.state.terms.content}
                        toggleCallback={(value) => {
                          if (value) {
                            this.setState({ didViewTerms: true });
                          }
                        }}
                        agreeCallback={(value) => {
                          this.setState({ agreeTerms: value });
                          this.props.setStateParent("agreeTerms", value);
                        }}
                      />
                    </div>
                    {this.state.errorMessages.agreeTerms ? <span className="text-red-500">{this.state.errorMessages.agreeTerms}</span> : null}
                  </div>
                ) : null}
                <h3 className="font-medium mt-8">Billing Information</h3>
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      name="Company Name"
                      label="Company Name"
                      onChange={handleChange("billing_company")}
                      value={values.billing_company}
                      // dense variant
                      margin="normal"
                      variant="outlined"
                      error={this.state.errorMessages.billing_company ? true : false}
                      helperText={this.state.errorMessages.billing_company}
                      inputProps={{ "aria-label": "Company Name" }}
                      defaultValue={values.billing_company}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="First Name"
                      label="First Name"
                      onChange={handleChange("billing_firstName")}
                      value={values.billing_firstName}
                      margin="normal"
                      error={this.state.errorMessages.billing_firstName ? true : false}
                      helperText={this.state.errorMessages.billing_firstName}
                      inputProps={{ "aria-label": "First Name" }}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      name="Last Name"
                      label="Last Name"
                      onChange={handleChange("billing_lastName")}
                      value={values.billing_lastName}
                      margin="normal"
                      error={this.state.errorMessages.billing_lastName ? true : false}
                      helperText={this.state.errorMessages.billing_lastName}
                      inputProps={{ "aria-label": "Last Name" }}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      name="billing_email"
                      label="Enter Email"
                      type="email"
                      onChange={handleChange("billing_email")}
                      defaultValue={values.billing_email}
                      margin="dense"
                      error={this.state.errorMessages.billing_email ? true : false}
                      helperText={this.state.errorMessages.billing_email}
                      inputProps={{ "aria-label": "Email" }}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <PhoneInput name="billing_phone" size="small" margin="normal" label="Billing Phone" fullWidth onChange={handleChange("billing_phone")} defaultValue={values.billing_phone} error={this.state.errorMessages.billing_phone} required />
                  </Grid>
                </Grid>

                <h4 className="font-medium mb-1 mt-8">Billing Address</h4>
                <Grid item sm={12} xs={12}>
                  <Address
                    onChange={this.handleChangeAddress}
                    defaultValues={{
                      address1: values.billing_address1,
                      address2: values.billing_address2,
                      city: values.billing_city,
                      state: values.billing_state,
                      country: values.billing_country,
                      zip: values.billing_zip,
                    }}
                    errors={{
                      address1: this.state.errorMessages.billing_address1,
                      address2: this.state.errorMessages.billing_address2,
                      city: this.state.errorMessages.billing_city,
                      state: this.state.errorMessages.billing_state,
                      country: this.state.errorMessages.billing_country,
                      zip: this.state.errorMessages.billing_zip,
                    }}
                    size="small"
                    margin="normal"
                  />
                </Grid>

                <div className="mt-8">
                  <div>
                    <h3 className="font-medium">Billing Credit Card</h3>
                    <InfoText text="We will not bill you until after the first 14 day free trial. You'll have a chance to review your order before your order is submitted." />

                    {!this.props.values.billing_token ? (
                      <Grid container spacing={1}>
                        <Grid item sm={12} xs={this.state.showStripeComponent ? 12 : 10}>
                          <div className="my-2">{this.state.errorMessages.billing_token ? <Alert severity={"error"}>{this.state.errorMessages.billing_token}</Alert> : null}</div>
                          <div className="p-0">
                            <Elements stripe={stripePromise}>
                              <StripeForm handleStripeToken={this.handleStripeToken} handleStripeError={this.handleStripeError} values={values} enabledFormEntry={this.state.showStripeComponent} />
                            </Elements>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div className="bg-gray-100 flex items-center justify-between mt-4 p-2 rounded">
                        <div>
                          <div className="flex items-center text-gray-500">
                            <CreditCardIcon />
                            <span className="ml-2 text-gray-400">Card ending in {this.props.values.billing_token.card.last4}</span>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <button
                            className="hover:bg-gray-200 px-2 py-1 rounded text-blue-500"
                            onClick={(e) => {
                              e.preventDefault();
                              this.toggleStripeComponent();
                            }}
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="mt-2 w-24">
                      <PoweredByStripe />
                    </div>
                  </div>
                </div>

                {true?null:<>
                <h4 className="font-medium mb-2 mt-8">Expected Monthly Balance Due</h4>
                <div className="border border-gray-300 border-solid rounded-lg">
                  {this.state.billingItems.map((item, idx) => {
                    return (
                      <div className="border-solid divide-gray-300 divide-solid divide-y px-4">
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Description</p>
                          <p className="text-right">{item.Item}</p>
                        </div>
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Quantity</p>
                          <p className="text-right">{item.Quantity}</p>
                        </div>
                        <div className="flex justify-between py-2 text-base">
                          <p className="text-gray-500">Price per license</p>
                          <p className="text-right">{this.toDollarAmount(item.PerItemPrice)}</p>
                        </div>
                      </div>
                    );
                  })}

                  {/* Subtotal  */}
                  <div className="bg-gray-100 border-solid divide-gray-300 divide-solid divide-y px-4 rounded-b-lg">
                    <div className="flex justify-between py-2 text-base">
                      <p className="text-gray-500">Subtotal</p>
                      <p className="text-right">{this.toDollarAmount(this.state.billingTotal)}</p>
                    </div>
                    <div className="flex items-center justify-between py-2 text-base">
                      <p className="text-gray-500">Taxes</p>
                      <p className="text-right text-sm">Invoice may include tax if applicable</p>
                    </div>
                    <div className="flex items-center justify-between py-1 text-base">
                      <div className="flex flex-wrap pr-4">
                        <p className="pr-1 text-base text-gray-800">Total due on</p>
                        <p className="font-medium">{this.state.billingDate}</p>
                      </div>

                      <p className="font-medium text-lg text-right">{this.toDollarAmount(this.state.billingTotal)}</p>
                    </div>
                  </div>
                </div>
                </>}
              </div>
            </div>
          )}
        </div>

        <FooterNav nextCallback={this.continue(values.path)} backCallback={this.back} nextDisabled={false} nextText={"Review"} errorMessage={Object.keys(this.state.errorMessages).length > 0 && "Please correct any errors above."} />
      </>
    );
  }
}
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export default AgreeTerms;
