/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { LoadingPleaseWait } from "@supportworks/react-components";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { FooterNav } from "components";
import TitleSubtile from "components/TitleSubtile";

const ChooseResources = ({ prevStep, nextStep, values, setStateParent, handleChange }) => {
  const api = new APICoreLiteSignup();
  const [resources, setResources] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    api
      .getResources()
      .then((result) => {
        if (result.status === 200) {
          let data = result.data;
          data.push({ id: -1, name: "My industry is not listed.", checked: false });
          data = data.map((o) => ({ ...o, checked: false }));
          setResources(data);
        } else {
          setErrorMessage("We apologize, there was an error. Please try again later.");
        }
      })
      .catch((err) => {
        setErrorMessage("We apologize, there was an error. Please try again later.");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckbox = (e) => {
    let arr = values.resources;
    console.log(values.resources);
    // eslint-disable-next-line eqeqeq
    let idx = arr.findIndex((obj) => obj.id == e.target.id); // findIndex does not work with ===
    if (idx > -1) {
      arr[idx].checked = e.target.checked;
      if (e.target.checked === false) {
        arr.splice(idx, 1);
      }
    } else {
      arr.push({ id: e.target.id, name: e.target.name });
    }

    setStateParent({ resources: arr });
  };

  return (
    <>
      <div className="flex flex-col flex-grow h-full items-center justify-center overflow-auto px-2 w-full md:py-12">
        {/* if no resources have loaded, lets load */}
        {resources.length < 1 ? (
          <div>
            <LoadingPleaseWait />
          </div>
        ) : (
          <>
            <div className="m-auto-0 w-full md:max-w-xl">
              <TitleSubtile title="Select your industry." subtitle="Select your industry to setup SolutionView with industry related resources." />
              <br />
              <FormGroup aria-label="resources" name="resources_api" value={values.resource}>
                {resources.map((item, index) => {
                  let checked = false;
                  let idx = values.resources.findIndex((obj) => {
                    return obj.id == item.id;
                  }); // obj.id (string) and item.id (number) do not work with === so we use NaNsafe to turn obj.id into a number

                  if (idx > -1) checked = true;
                  return (
                    <div key={"key" + index} className={checked ? "w-full border border-solid rounded border-primary-300 bg-secondary-100 my-1" : "w-full border border-solid rounded border-gray-300 hover:bg-gray-100 my-1 text-gray-600 font-normal"}>
                      <FormControlLabel
                        key={index}
                        label={item.name}
                        style={{
                          width: 100.0 + "%",
                          margin: "0px",
                        }}
                        control={<Checkbox id={`${item.id}`} checked={checked} name={item.name} label={item.name} onClick={handleChangeCheckbox} />}
                      />
                    </div>
                  );
                })}
              </FormGroup>
              {/* <InfoText text={"If your industry isn't listed?"} /> */}
            </div>
          </>
        )}
      </div>

      <FooterNav nextCallback={(e) => nextStep(2)} backCallback={prevStep} nextDisabled={!values.resources.length} errorMessage={errorMessage} />
    </>
  );
};

export default ChooseResources;
