export const NotFound = () => {
  return (
    <>
      <div className="flex flex-col flex-grow h-full items-center overflow-auto px-2 w-full sm:py-12 text-center">
        <div className="m-auto-0 w-full sm:max-w-xl">
          <h1 className="font-semibold text-3xl mb-2">404</h1>
          <p className="">The requested resource could not be found.</p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
