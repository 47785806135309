import { TextField, Alert } from "@mui/material";
import { APICoreLiteSignup } from "@supportworks/api-core-lite-signup";
import { useState } from "react";
import { LeftSectionHero } from "../components";
import { ForgotPasswordFormValidation } from "../validation/ForgotPasswordFormValidation";
import { useFormik } from "formik";

const api = new APICoreLiteSignup();

export const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [didReset, setDidReset] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    show: false,
    severity: "",
  });
  const handleSignup = async (values) => {
    await api
      .postForgotPassword({
        username: values.email,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          //success
          setDidReset(true);
          formik.resetForm();
          setAlert({
            message:
              "Password reset instructions have been sent to your email.",
            show: true,
            severity: "success",
          });
        } else {
          // failed
          setAlert({
            message: "Failed to request password reset.",
            show: true,
            severity: "error",
          });
        }
      })
      .catch((err) => {
        // failed
        setIsLoading(false);
        setAlert({
          message: "Failed to request password reset.",
          show: true,
          severity: "error",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordFormValidation,
    onSubmit: (values) => {
      handleSignup(values);
    },
  });

  return (
    <div className="h-full w-full sm:px-0">
      <div className="flex flex-col h-full sm:flex-row">
        {/* Left content */}
        <LeftSectionHero title="SolutionView" subtitle="Password Reset" />
        {/* Right content */}
        <div className="flex flex-col flex-grow min-h-0 p-3 sm:w-2/3">
          <div className="flex flex-col flex-grow h-full items-center overflow-auto w-full sm:py-12">
            <div className="m-auto-0 w-full sm:max-w-lg">
              <div className="mb-2">
                <h1 className="mb-2 text-xl">Forgot your password?</h1>
                <p className="text-gray-600">
                  We'll send you a link to reset your password.
                </p>
                <div className={didReset ? "hidden" : "flex flex-col"}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <TextField
                      name="email"
                      placeholder="johnny@appleseed.com"
                      label="Email"
                      margin="normal"
                      fullWidth
                      inputProps={{ "aria-label": "Email" }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email
                          ? Boolean(formik.errors.email)
                          : false
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      onBlur={formik.handleBlur}
                      disabled={isLoading}
                    />

                    <button
                      type="submit"
                      className="active bg-primary-500 hover:bg-primary-500-dark px-4 py-2 rounded text-base text-white"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {formik.isSubmitting ? "Sending.." : "Reset Password"}
                    </button>
                  </form>
                </div>
              </div>
              {alert.show && (
                <Alert severity={alert.severity}>{alert.message}</Alert>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
