import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#1870e7",
    },
    secondary: {
      main: "#6accf3",
    },
  },
});
export default theme;
