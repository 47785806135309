import * as yup from "yup";

export const PasswordResetValidation = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .min(8, "Confirm password must be at least 8 characters")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
